import React, { Component } from "react";
import queryString from "query-string";

// app header
import AppHeader from "../components/AppHeader/AppHeader";

// seo
import Seo from "../components/Seo";

// hook
import { useBookPackQuery } from "../hooks/useBookPackQuery";
import { useWindowSize } from "../hooks/useWindowSize";

// constant
import constant from "../constants";

// utils
import { validateServiceabilityBookPack, splitName } from "../utils";

// services
import { sendToCT } from "../services/Clevertap";
import { sendToLSQ } from "../services/LeadSquared";

// email
import { sendEmailNotifecation } from "../services/SendEmail";

// componentts
import BookPackHeading from "../components/BookPack/BookPackHeading";
import BookPackForm from "../components/BookPack/BookPackForm";

// api actions
import {
  getClientDetails,
  handlePaymentOfBookPack,
} from "../actions/bookPackActions";

class BookPack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      type: "DIRECT_M2_ANNUAL",
      planCode: "",
      planPrice: 0,
      coupon: "",
      couponApplied: false,
      couponStatus: "",
      clintInfo: {},
      annualPlanPrice: 0,
      selectedPlan: "annualPlan",
      productId: "",
      pincodeCategory: "",
      installmentPlanPrice: 0,
      firstInstallmentPrice: 0,
    };
  }

  // Function -> To fetch the client details
  getInitialData = async clientId => {
    try {
      // api call -> to get client details
      const response = await getClientDetails(clientId);
      this.setState({ clintInfo: response.results[0], fetching: true });
    } catch (error) {
      console.error("client api server error", error);
      this.setState({
        serverError: "Unable to fetch your details. Try again",
        fetching: true,
      });
    }
  };

  componentDidMount() {
    const annualPlan = this.props.bookPack.annualPlan[0];
    const installmentPlan = this.props.bookPack.installmentPlan[0];

    let plan = null;
    if (this.state.selectedPlan === "installmentPlan") {
      plan = installmentPlan;
    } else if (this.state.selectedPlan === "annualPlan") {
      plan = annualPlan;
    }

    plan &&
      this.setState({
        planCode: plan.planCode,
        planPrice: Number(plan.planPrice),
        planDay: plan.planDay,
        type: plan.planType,
        productId: plan.productId,
        annualPlanPrice: Number(plan.planPrice),
        installmentPlanPrice: Number(installmentPlan.planPrice),
      });

    const { clientId } = queryString.parse(this.props.location.search);
    if (clientId) {
      this.getInitialData(clientId);
    }
  }

  // Function -> To handle the payment of book pack
  handlePayNow = async values => {
    const languageArr = [];
    if (values.language && values.language !== "") {
      values.language.map(item => {
        languageArr.push(item.name);
        return languageArr;
      });
    }

    // util function -> to split the name
    const { firstName, lastName } = splitName(values.name);

    // leadSquared params
    let leadSquaredParams = {
      FirstName: firstName,
      LastName: lastName,
      Phone: values.phone,
      EmailAddress: values.email,
      mx_Website_CTA: "book_pack",
      mx_Latest_Source: "New Website",
    };

    // payment v3
    let paymentBody = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: values.phone,
      email: values.email,
      planCode: this.state.planCode,
      preferredM2Plan: this.state.type,
      m1PlanCode: this.state.planCode,
      m2PlanCode: this.state.planCode,
      sourceType: "DIGITAL_ORGANIC_TW",
      couponCode: this.state.coupon.toUpperCase(),
      languageList: languageArr,
      subscriptionType:
        this.state.selectedPlan === "annualPlan" ? "YEARLY" : "HALF_YEARLY",
      address: {
        postalCode: values.pinCode ? values.pinCode : "",
        city: values.city,
        line1: "",
      },
    };

    try {
      this.setState({ loading: true });
      // LSQ Call
      await sendToLSQ(leadSquaredParams);
      // Zoho Payment Call
      const response = await handlePaymentOfBookPack(paymentBody);
      const paymentUrl = response.results[0].url;
      // console.log("paymentUrl..", paymentUrl)

      this.setState({ loading: false });
      window.location.href = paymentUrl;

      // clevertap event
      sendToCT("Enrollment Initiated", {
        action: "BookPack_Buy_Now Clicked",
        name: values.name,
        phone: values.phone,
        email: values.email,
        pincode: values.pinCode,
        coupon: this.state.coupon,
        paymentPlan: this.state.planCode,
      });
    } catch (error) {
      // send an email
      sendEmailNotifecation(
        window.location.href,
        paymentBody,
        error || {},
        "/marketing/v3/prospect"
      );

      console.error("payment api server error", error);
      this.setState({
        loading: false,
        serverError: constant.BOOKPACK_ERROR_MESSAGES.serverError,
      });
    }
  };

  // This function executes when we apply coupon
  handleCouponUpdate = (planPrice, coupon, discountAmt) => {
    if (coupon) {
      this.setState({
        showConfetti: true,
        planPrice: Number(planPrice),
        coupon: coupon,
        couponApplied: true,
      });

      // update plan price of selected plan after coupon applied
      if (this.state.selectedPlan === "installmentPlan")
        this.setState({
          installmentPlanPrice: Number(planPrice),
          firstInstallmentPrice:
            Number(this.state.firstInstallmentPrice) - Number(discountAmt),
        });
      if (this.state.selectedPlan === "annualPlan")
        this.setState({ annualPlanPrice: Number(planPrice) });

      setTimeout(
        function () {
          this.setState({ showConfetti: false });
        }.bind(this),
        5000
      );
    }
  };

  // This function executes when we remove the applied coupon
  removedCoupon = () => {
    const { selectedPlan } = this.state;
    const plan = this.props.bookPack[selectedPlan][0];
    const pincodeCategory = this.state.pincodeCategory;
    const data =
      this.props.bookPack[selectedPlan][0][pincodeCategory].split("#");

    // update state based on pincode category ie GOLD(serviceable) or SILVER(Remote)
    // when coupon is removed
    if (
      pincodeCategory === constant.BOOKPACK_CATEGORIES.TIER_I ||
      pincodeCategory === constant.BOOKPACK_CATEGORIES.TIER_II
    ) {
      // update plan price of selected plan after coupon removed
      if (selectedPlan === "installmentPlan")
        this.setState({
          installmentPlanPrice: Number(data[1]),
          firstInstallmentPrice: Number(data[2]),
        });
      if (selectedPlan === "annualPlan")
        this.setState({ annualPlanPrice: Number(data[1]) });

      this.setState({
        planCode: data[0],
        planPrice: Number(data[1]),
        couponApplied: false,
        coupon: "",
      });
    } else {
      this.setState({
        planCode: plan.planCode,
        planPrice: Number(plan.planPrice),
        planDay: plan.planDay,
        type: plan.planType,
        couponApplied: false,
        coupon: "",
        annualPlanPrice: Number(plan.planPrice),
        installmentPlanPrice: Number(plan.planPrice),
      });
    }
  };

  // This function updates the planCode and planPrice
  // based on the pincode category i.e GOLD or SILVER only
  // and for PLATINUM category planPrice and planCode will not get updated.
  handlePincodePricing = async category => {
    try {
      const planData = await validateServiceabilityBookPack(
        category,
        this.props.bookPack.annualPlan[0],
        this.props.bookPack.installmentPlan[0]
      );

      // for Serviceable(GOLD) and remote(SILVER) pincodes
      if (planData.categoryVal) {
        this.setState({
          planCode: planData.planCode,
          planPrice: Number(planData.planPrice),
          annualPlanPrice: Number(planData.planPrice),
          pincodeCategory: category,
          installmentPlanPrice: Number(planData.planPrice),
          firstInstallmentPrice: Number(planData.firstInstallmentPrice),
          selectedPlan: "annualPlan",
        });
      } else {
        // for PLATINUM category(Non-serviceable) pincode pricing will get updated to default value.
        this.setState({
          planCode: planData.planCode,
          planPrice: Number(planData.planPrice),
          annualPlanPrice: Number(planData.planPrice),
          pincodeCategory: category,
          installmentPlanPrice: Number(planData.planPrice),
        });
      }
    } catch (error) {
      console.error("Error in getting plan details based on pincode", error);
    }
  };

  // Function -> Handle radio button plan cards
  handlePlan = val => {
    const pincodeCategory = this.state.pincodeCategory;
    const plan = this.props.bookPack[val][0];
    const annualPlanData =
      this.props.bookPack.annualPlan[0][pincodeCategory].split("#");
    const installmentData =
      this.props.bookPack.installmentPlan[0][pincodeCategory].split("#");

    this.setState({
      selectedPlan: val,
      planCode:
        val === "annualPlan"
          ? annualPlanData[0]
          : installmentData[0],
      planPrice:
        val === "annualPlan"
          ? Number(annualPlanData[1])
          : Number(installmentData[1]),
      planDay: plan.planDay,
      type: plan.planType,
      coupon: "",
      couponApplied: false,
      firstInstallmentPrice: Number(installmentData[2])
    });

    // when coupon applied and user changes the plan, update plan price of previous plan selected to original plan price
    if (
      this.state.couponApplied === true &&
      val === "installmentPlan"
    )
      this.setState({
        planCode: annualPlanData[0],
        annualPlanPrice: Number(annualPlanData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
      });

    if (this.state.couponApplied === true && val === "annualPlan")
      this.setState({
        planCode: installmentData[0],
        installmentPlanPrice: Number(installmentData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
      });
  };

  render() {
    const {
      annualPlanPrice,
      clintInfo,
      loading,
      productId,
      planPrice,
      planDay,
      selectedPlan,
      serverError,
      installmentPlanPrice,
      firstInstallmentPrice,
    } = this.state;

    const { appHeader } = queryString.parse(this.props.location.search);

    return (
      <div className="bgLightgray" style={{ minHeight: "92vh" }}>
        <Seo
          title="Reverse Diabetes, Obesity, PCOS & More"
          description="AI-driven treatment to reverse Diabetes, Hypertension, Obesity & Other Chronic Conditions. 97% reduced blood sugar & meds, 98% eliminated symptoms. Start today."
        />

        {appHeader === "mobile" && <AppHeader text="BOOK PACK" isBack={true} />}

        {/* Heading */}
        <BookPackHeading />

        {/* Form */}
        <BookPackForm
          price={planPrice}
          handlePayNow={this.handlePayNow}
          loading={loading}
          handleCouponUpdate={this.handleCouponUpdate}
          coupon={this.state.coupon}
          removedCoupon={this.removedCoupon}
          selectedPlan={selectedPlan}
          name={clintInfo.name}
          email={clintInfo.email}
          phone={clintInfo.phone}
          postalCode={clintInfo.postalCode}
          locationSearch={this.props.location.search}
          productId={productId}
          annualPlanPrice={annualPlanPrice}
          planDay={planDay}
          showCouponSection={true}
          windowSize={this.props.windowSize}
          couponType="bookpackCoupon"
          handlePincodePricing={this.handlePincodePricing}
          leadSquaredParam="book_pack"
          handlePlan={this.handlePlan}
          installmentPlanPrice={installmentPlanPrice}
          firstInstallmentPrice={firstInstallmentPrice}
        />
        <div className="mt-2 pb-3 text-center text-danger">
          {serverError} &nbsp;
        </div>
      </div>
    );
  }
}

const WithContext = props => {
  const bookPackPayload = useBookPackQuery();
  const windowSize = useWindowSize();
  return (
    <BookPack {...props} bookPack={bookPackPayload} windowSize={windowSize} />
  );
};

export default WithContext;
